.project-header {
  font-size: 3rem;
  padding: 2rem 0;
  letter-spacing: 0.1rem;
}
.project-wrapper {
  gap: 3rem;
}
/* Small and medium devices */
@media only screen and (max-width: 992px) {
  .project-wrapper {
    gap: 2rem;
  }
}

/* Large devices */
@media only screen and (min-width: 992px) {
  .project-wrapper {
    gap: 3rem;
  }
}
