.panel-heading-wrapper {
  border: 2px solid #04addc;
  padding: 0.2rem 1rem;
  border-radius: 1.5rem;
  width: fit-content;
}
.panel-heading-wrapper > a {
  font-size: 1.2rem;
  font-weight: 600;
}
.panel-collapse-inner {
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: #eeeef2;
  border-radius: 0.5rem;
  max-width: 600px;
}

/* Small devices */
@media only screen and (max-width: 576px) {
  .panel-heading-wrapper > a {
    font-size: 1rem;
  }
}

/* Medium devices */
@media only screen and (min-width: 576px) {
  .panel-heading-wrapper > a {
    font-size: 1.1rem;
  }
}

/* Large devices */
@media only screen and (min-width: 992px) {
  .panel-heading-wrapper > a {
    font-size: 1.2rem;
  }
}
